import {} from '../css/main.scss';
import '../js/lazysizes';
import "../js/bootstrap";
import "../js/fontawesome";

import ExternalLinks  from "./externallinks";
import PageTimer from "./pagetimer";


    (new PageTimer()).logToConsole();
    (new ExternalLinks('body')).addLinkTarget();

    let glides = document.getElementsByClassName('glide');
    if (glides.length) {
        new Promise((resolve) => {
            import(/* webpackChunkName: "glider" */ '../js/glide').
                then((Glider) => {
                    let glide = new Glider.default(glides);
                    resolve(glide.initialize());
                });
        })
    }
 
    let popups = document.querySelectorAll('[data-mfp-type]');
    if(popups.length) {
        new Promise((resolve) => {
            import(/* webpackChunkName: "magnific" */ '../js/magnificpopup')
                .then((Magnific) => {
                    let magnific =  new Magnific.default(popups);
                    resolve(magnific.initialize())
                })
        })
    }

    let forms = document.getElementsByClassName('needs-validation');
    if(forms.length) {
        new Promise((resolve) => {
            import(/* webpackChunkName: "forms" */ '../js/form')
                .then((Forms) => {
                    let form =  new Forms.default(forms);
                    resolve(form.submitButton().validate())
                })
        })
    }


    let shares = document.querySelectorAll('[data-share-default]');
    if(shares.length) {
        new Promise((resolve) => {
            import(/* webpackChunkName: "share" */ '../js/share')
                .then((ShareHandler) => {
                    let socialShare =  new ShareHandler.default();
                    resolve(socialShare.initialize())
                })
        })
    }


// Accept HMR as per: https://webpack.js.org/api/hot-module-replacement#accept
if (module.hot) {
    module.hot.accept();
}

$(window).on('scroll load', function(){
    $('nav.position-fixed').toggleClass('scrolled', $(this).scrollTop() > 50);
    $('#toTop').toggleClass('alpha-0', $(this).scrollTop() < 500);
});

// smoothe scrolling - set offset
$(function() {
    // Add smooth scrolling to anchors
    $(document).on('click', '.anchor-link', function (event) {
        event.preventDefault();
        $('html, body').animate({ scrollTop: $($.attr(this, 'href')).offset().top + -120 }, 500);
    });

    // Add smoothe scrolling to back to top button 
    $(document).on('click', '#toTop', function (event) {
        event.preventDefault();
        $('html, body').animate({ scrollTop: 0 }, 500);
    });

    // Add open class to navbar to allow for 
    // various style changes on mobile devices
    $(document).on('click', '.navbar-toggler', function (event) {
        $('nav.position-fixed').toggleClass('open');
    });
});

// Play video and hide overlay on 
// Hide vide controls when paused
$('.video-file').each(function(){
    var video   = $(this).find('video')[0];
    video.removeAttribute("controls")
});
$('.video-file').on("click", function() {
    var video   = $(this).find('video')[0];
    var overlay = $(this).find('.video-overlay');
    if (video.paused == false) {
        video.pause();
        overlay.fadeIn(100);
        console.log('video paused');
    } else {
        video.play();
        overlay.fadeOut(100);
        console.log('video playing');
    }

    if (video.hasAttribute("controls")) {
        video.removeAttribute("controls")
    } else {
        video.setAttribute("controls", "controls")
    }
});