import { library, dom, config } from '@fortawesome/fontawesome-svg-core';
import { faSearch as faSearchLight,  faEye, faGlobe, faTimes as faTimesLight, faFileWord, faFilePowerpoint, faFileImage, faFileSpreadsheet, faFileArchive, faFilePdf, faFile, faFileAlt, faPlayCircle, faArrowUp, faEdit, faSpinner, faUser as faUserLight, faChevronRight, faChevronLeft } from '@fortawesome/pro-light-svg-icons';
import { faChevronRight as faChevronRightRegular, faChevronLeft as faChevronLeftRegular, faChevronUp as faChevronUpSolid, faSearch as faSearchRegular, faTimes as faTimesRegular, faEdit as faEditRegular, faLink, faDownload } from '@fortawesome/pro-regular-svg-icons';
import { faSearch as faSearchSolid, faCircle, faSpinner as faSpinnerSolid, faQuoteLeft, faQuoteRight } from '@fortawesome/pro-solid-svg-icons';
import { faFacebookF, faTwitter, faLinkedinIn, faInstagram, faPinterestP } from '@fortawesome/free-brands-svg-icons';
import { faBars, faSearch as faSearchDuo } from '@fortawesome/pro-duotone-svg-icons';

config.searchPseudoElements = true;
config.keepOriginalSource = false;

library.add(
	faArrowUp,
	faBars,
	faChevronLeft,
	faChevronLeftRegular,
	faChevronRight,
	faChevronRightRegular,
	faChevronUpSolid,
	faCircle,
	faDownload,
	faEdit,
	faEditRegular,
	faEye,
	faFacebookF,
	faFile,
	faFileAlt,
	faFileArchive,
	faFileImage, 
	faFilePdf,
	faFilePowerpoint, 
	faFileSpreadsheet, 
	faFileWord, 
	faGlobe,
	faInstagram,
	faPinterestP,
	faLink,
	faLinkedinIn,
	faPlayCircle,
	faQuoteLeft, 
	faQuoteRight,
	faSearchDuo,
	faSearchLight, 
	faSearchRegular,
	faSearchSolid,
	faSpinner,
	faSpinnerSolid,
	faTimesLight,
	faTimesRegular,
	faTwitter,
	faUserLight
);
dom.i2svg();
dom.watch();